import { FC, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button } from '@getgo/chameleon-web-react-wrapper';

import { useAppDispatch, useAppSelector } from 'hooks';
import { CybersourceFingerprint } from 'lib/cybersource-fingerprint';
import { postProcessRenewal } from 'modules/cog';
import { isProcessRenewalLoading } from 'modules/cog/cog-selector';
import { showErrorSnack } from 'modules/error';
import { renewalDate, sessionId } from 'modules/global-wrapper';
import {
  sessionCurrencyCode,
  sessionProducts,
  sessionQuoteId,
  sessionTotalAmount,
  sesssionCancelRedirectUrl,
} from 'modules/session-details';
import Track, { PaynowCTA, Revenue } from 'modules/tracking';
import { postTransactionUseDDPay, transactionsDDIsLoading, useDDPayResp } from 'modules/transaction-dd';
import { postTransactionUseAchPay, transactionsAchIsLoading, useAchPayResp } from 'modules/transactions-ach';
import { postProcessQuote } from 'modules/ucs';
import { isProcessQuoteLoading } from 'modules/ucs/ucs-selector';
import { AchDetails, DdDetails } from 'types/payment-method';
import {
  ACH,
  PAYMENT_TYPE_DD,
  PURCHASE_FLOW_QUOTE,
  PURCHASE_FLOW_RENEWALS,
  PURCHASE_FLOW_SUBSCRIPTION,
} from 'utils/constants';
import st from 'utils/shared-translations';
import { getProductAdminDataFromCookie, removeProductAdminDataFromCookie } from 'utils/ui-utils';

interface AchDDBuyNowProps {
  paymentDetails: AchDetails | DdDetails;
  paymentType: string;
}

let isDisabled = false;

const AchDDBuyNow: FC<AchDDBuyNowProps> = ({ paymentDetails, paymentType }): JSX.Element => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const [isComponentLoading, setComponentLoading] = useState(false);

  const selectedSessionId = useAppSelector(sessionId);
  const selectedQuoteProcessIsLoading = useAppSelector(isProcessQuoteLoading);
  const selectedProcessRenewalIsLoading = useAppSelector(isProcessRenewalLoading);
  const selectedRenewalDate = useAppSelector(renewalDate);

  // Session details selectors
  const selectedQuoteTotalPrice = useAppSelector(sessionTotalAmount);
  const selectedQuoteKey = useAppSelector(sessionQuoteId);
  const selectedSessionProducts = useAppSelector(sessionProducts);
  const selectedSessionCurrencyCode = useAppSelector(sessionCurrencyCode);
  const selectedSessionCancelUrl = useAppSelector(sesssionCancelRedirectUrl);

  // Transaction selectors
  const selectedTransactionAchLoading = useAppSelector(transactionsAchIsLoading);
  const selectedTransactionDDLoading = useAppSelector(transactionsDDIsLoading);
  const selectedTransactionUseAchResp = useAppSelector(useAchPayResp);
  const selectedTransactionUseDDResp = useAppSelector(useDDPayResp);

  const productAdminData = getProductAdminDataFromCookie();

  const processQuote = useCallback(() => {
    const data = {
      quoteCode: selectedQuoteKey,
      payload: {
        grossAmount: selectedQuoteTotalPrice,
        sessionId: selectedSessionId,
        paymentType,
        ...(Object.keys(productAdminData).length > 0 && { productAdmin: productAdminData }),
      },
    };

    dispatch(postProcessQuote(data))
      .unwrap()
      .then(() =>
        Track(Revenue, {
          orderList: selectedSessionProducts,
          currencyCode: selectedSessionCurrencyCode,
          purchaseFlow: selectedSessionCancelUrl.includes('subscriptions')
            ? PURCHASE_FLOW_SUBSCRIPTION
            : PURCHASE_FLOW_QUOTE,
          paymentMethod: paymentType,
          quoteId: selectedQuoteKey,
          amount: selectedQuoteTotalPrice,
        }),
      )
      .finally(() => Object.keys(productAdminData).length > 0 && removeProductAdminDataFromCookie());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    selectedQuoteKey,
    selectedQuoteTotalPrice,
    selectedSessionCancelUrl,
    selectedSessionCurrencyCode,
    selectedSessionId,
    selectedSessionProducts,
    paymentType,
  ]);

  const processRenwal = useCallback(() => {
    const payload = {
      date: selectedRenewalDate,
      sessionId: selectedSessionId,
      amount: selectedQuoteTotalPrice.toString(),
    };
    dispatch(postProcessRenewal(payload))
      .unwrap()
      .then(() =>
        Track(Revenue, {
          orderList: selectedSessionProducts,
          currencyCode: selectedSessionCurrencyCode,
          purchaseFlow: PURCHASE_FLOW_RENEWALS,
          paymentMethod: paymentType,
          quoteId: selectedQuoteKey,
          amount: selectedQuoteTotalPrice,
        }),
      );
  }, [
    dispatch,
    selectedQuoteKey,
    selectedQuoteTotalPrice,
    selectedRenewalDate,
    selectedSessionCurrencyCode,
    selectedSessionId,
    selectedSessionProducts,
    paymentType,
  ]);

  useEffect(() => {
    if (selectedTransactionUseAchResp || selectedTransactionUseDDResp) {
      if (selectedTransactionUseAchResp?.paymentMethodKey || selectedTransactionUseDDResp?.paymentMethodKey) {
        selectedRenewalDate ? processRenwal() : processQuote();
      }
    }
  }, [selectedRenewalDate, processQuote, processRenwal, selectedTransactionUseAchResp, selectedTransactionUseDDResp]);

  // Pay now button click handler
  const payNow = () => {
    isDisabled = true;
    Track(PaynowCTA, {});
    setComponentLoading(true);

    const payload = {
      fingerPrintSessionId: CybersourceFingerprint.id,
      paymentMethodKey: paymentDetails.paymentMethodKey,
    };

    if (paymentType === ACH) {
      dispatch(postTransactionUseAchPay(payload))
        .unwrap()
        .catch(() => {
          dispatch(showErrorSnack(st['alert.error.general.refreshtryagain']));
          setComponentLoading(false);
        });
    } else if (paymentType === PAYMENT_TYPE_DD) {
      dispatch(postTransactionUseDDPay(payload))
        .unwrap()
        .catch(() => {
          dispatch(showErrorSnack(st['alert.error.general.refreshtryagain']));
          setComponentLoading(false);
        });
    }
  };

  return (
    <Button
      isLoading={
        isComponentLoading ||
        selectedTransactionAchLoading ||
        selectedQuoteProcessIsLoading ||
        selectedProcessRenewalIsLoading ||
        selectedTransactionDDLoading
      }
      disabled={isDisabled}
      size="large"
      fullWidth
      onClick={payNow}
    >
      {intl.formatMessage(st['pay.now.cta'])}
    </Button>
  );
};

export default AchDDBuyNow;
